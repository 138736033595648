<template>
    <v-container
      id="google-maps"
      fluid
      tag="section"
      v-if = "usertype == 'admin'"
    >
      <v-row>
        <v-col cols="12">
          <base-material-card
            color="success"
            title="IIC"
            class="px-5 py-3"
          >
  <v-row>
        <v-col
      cols="12"
      md="12"
      >
      <client-only>
    <vue-editor v-model="AboutUS[0].IIC" />
  </client-only>
  
    </v-col>
      <v-btn 
      color="primary"
      dark
      class="mb-2"
      @click="updateabout"
      style="margin-top: 10px;">Save</v-btn>
  </v-row>
          </base-material-card>
        </v-col>
  
        </v-row>
  
    </v-container>
  </template>
  <script>
  
  import { db ,fb } from '@/db'
  import {gmapApi} from 'vue2-google-maps'
  import { VueEditor } from "vue2-editor";
  
    export default {
      name: 'DashboardIcons',
      components: {
      VueEditor
      },
    watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
     computed: {
       google: gmapApi,
        usertype(){
          return this.$store.state.userType
        },
        AboutUS(){
            return this.$store.state.Info  
        },

      },
      created(){
          this.$store.dispatch('bindInfo');
  
      },
      methods :{
  
      updateabout(){
      return db.collection('Information').doc('OurInformation')
                  .update({"IIC" : this.AboutUS[0].IIC})
                  .then(_ => {  
                      alert('Save Successfully')
  
                    })
      },
  
      }
  }
  
  
  </script>